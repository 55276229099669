import { makeStyles } from '@material-ui/core';

export const myStyles = makeStyles(() => ({
  'capsules-bg': (data) => ({
    background: `url(${data?.capsuleBackgroundImage?.file?.url}?fm=webp&q=100) 0 0 no-repeat`,
  }),
  'dot-matrix': (data) => ({
    background: `url(${data?.dotMatrixBackgroundImage?.file?.url}?fm=webp&q=100) 0 0 no-repeat`,
  }),
  'bg-left-arrow': (data) => ({
    background: `url(${data?.leftArrowImage?.file?.url}?fm=webp&q=100) no-repeat`,
    '&:hover': {
        background: `url(${data?.backButtonHoverImage?.file.url}?fm=webp&q=100) no-repeat`,
      },
}),
}));
  

export const richTextStyles = makeStyles((data) => {
  return {
    richTextHeading: (styles) => ({
      color: styles?.headingTextColor,
      '& h1,h2,h3,h4,h5,h6,p': {
        fontSize: `${styles?.headingTextFontSize}px`,
        margin: '5px 0px',
        lineHeight: 1.2,
        fontWeight:styles?.headingFontWeight,
        fontFamily:data.typography.body1.fontFamily,
        paddingBottom: '5px'
      },
    }),
    richTextDescription: (styles) => ({
      fontSize: `${styles?.descriptionTextFontSize}px`,
      color: styles?.descriptionTextColor,
      margin: '5px 0px',
      '& h1,h2,h3,h4,h5,h6,p': {
        margin: '5px 0px',
        lineHeight: 1.2,
        fontWeight:styles?.descriptionFontWeight,
        fontFamily:data.typography.body1.fontFamily,
        paddingBottom: '5px'
      },
    }),
  };
});

export const formRichTextStyles = makeStyles((data) => {
  return {
    backButton:(styles)=>({
      paddingTop:"60px"
  }),
    richTextHeading: (styles) => {
      return({
      color: styles?.headingFontColor,
      '& h1,h2,h3,h4,h5,h6,p': {
        margin: '5px 0px',
        lineHeight: 1.2,
        fontWeight:styles.headingFontWeight,
        fontFamily:data.typography.body1.fontFamily,
        fontSize: `${styles?.headingFontSize}px`
      },
    })},
    richTextDescription: (styles) => ({
      color: styles?.descriptionFontColor,
      margin: '20px 0px',
      '& h1,h2,h3,h4,h5,h6,p': {
        margin: '5px 0px',
        lineHeight: 1.2,
        fontWeight:styles?.descriptionFontWeight,
        fontFamily:data.typography.body1.fontFamily,
        fontSize: `${styles?.descriptionFontSize}px`,
      },
    }),
  };
});
