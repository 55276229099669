import { makeStyles } from '@material-ui/core';

export const myStyles = makeStyles(() => ({
  'bg-donuts-left': (data) => ({
    background: `url(${data?.donutBackgroundImage?.file?.url}?fm=webp&q=100) no-repeat`,
  }),

  'map-image-matrix': (data) => ({
    '&:before': {
      background: `url(${data?.smallDotMatrixImage?.file?.url}?fm=webp&q=100) 0 0 no-repeat`,
    },
  }),
}));
