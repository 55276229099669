import React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

const DefaultFormTemplate = (props) => (
  <Grid container direction="column">
    {props?.properties?.map((element) => (
      <Grid item>
        <Box sx={{ margin: '10px' }}>{element.content}</Box>
      </Grid>
    ))}
  </Grid>
);

export default DefaultFormTemplate;
