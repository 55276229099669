import React from 'react';
// import { ThemeProvider } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import take from 'lodash/take';
import slice from 'lodash/slice';

const TwoColumnTemplate = (props) => {
  const { properties } = props;
  const taken = 4;
  return (
    <Grid container xl={9} lg={9} direction="row" justifyContent="flex-start" alignItems="flex-start">
      <Grid item lg={6} md={6} xl={6} sm={12} xs={12}>
        {take(properties, taken)?.map((element, idx) => (
          <Box key={`taken_property_${idx}`} sx={{ margin: '6px 6px 12px 6px' }}>
            {element.content}
          </Box>
        ))}
      </Grid>
      <Grid item lg={6} md={6} xl={6} sm={12} xs={12}>
        {slice(properties, taken, properties.length)?.map((element, idx) => (
          <Box key={`remaing_property_${idx}`} sx={{ margin: '6px 6px 12px 6px' }}>
            {element.content}
          </Box>
        ))}
      </Grid>
    </Grid>
  );
};

export default TwoColumnTemplate;
