import React from 'react';
import mapKeys from 'lodash/mapKeys';
import replace from 'lodash/replace';
import mapValues from 'lodash/mapValues';
import DynamicForm from '../DynamicForm';
import TwoColumnTemplate from '../DynamicForm/templates/twoColumnTemplate';

const reStructureUISchema = (uiSchema) => mapValues(uiSchema, (val) => mapKeys(val, (_, key) => replace(key, '_', ':')));

const JsonFormContainer = ({ schema = {}, uiSchema = {}, handleDynamicFormSubmit,errorSchema={}, formSubmit, isSubmitEnabled }) => (
  <div>
    <DynamicForm
      onSubmit={handleDynamicFormSubmit}
      schema={schema}
      uiSchema={reStructureUISchema(uiSchema)}
      template={TwoColumnTemplate}
      themeName="TwoColumnTemplateTheme"
      errorSchema={errorSchema}
      formSubmit={formSubmit}
      isSubmitEnabled={isSubmitEnabled}
    />
  </div>
);

export default JsonFormContainer;
