import React, { useState } from 'react';
import GetinTouch from '../GetInTouch';
import GlobalPresence from '../GlobalPresence';
import JoinOurTeam from '../JoinOurTeam';
import Container from '@material-ui/core/Container';
import './ContactUs.scss';
import RequestFormPopup from '../RequestFormPopup';

export default function ContactUs({ data, basePath, backgroundImg }) {
  const [regionalAddress, setRegionalAddress] = useState(
    data?.contentfulContactUs?.chipButton?.chipButtons[0]
  );
  return (
    <>
      <div className="contactus-main">
        <GetinTouch setRegionalAddress={setRegionalAddress} data={data} basePath={basePath} />
        <GlobalPresence data={data} regionalAddress={regionalAddress} />
        <div className="contactus-joinus">
          <Container maxWidth="xl" className='no-gutter'>
          <JoinOurTeam
            data={data?.contentfulContactUs?.joinUsSection}
            matrixImg={data?.contentfulContactUs?.smallDotMatrixImage}
            basePath={basePath}
          />
          </Container>
          <RequestFormPopup data={data?.contentfulContactUs?.pardotForm} images={backgroundImg} />
        </div>
      </div>
    </>
  );
}
