import React from 'react';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';

const FormFooter = ({ variant = 'default', formSubmit, isSubmitEnabled }) => (
  <>
    {variant === 'default' && (
      <Grid container xl={9} lg={9} md={12} direction="row" justifyContent="flex-end" alignItems="flex-start">
          <Button variant="contained" type="submit" disabled={!isSubmitEnabled} className='btn-get-touch-submit'>
            {formSubmit}
          </Button>
      </Grid>
    )}
  </>
);
export default FormFooter;
