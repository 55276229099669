import React, { useState, useContext } from 'react';
import Button from '@mui/material/Button';
import Backdrop from '@mui/material/Backdrop';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import FormContainer from './FormContainer';
import './GetInTouch.scss';
import { v4 as uuidv4 } from 'uuid';
import ThemeContext from '../../utils/theme-context';
import { myStyles } from './styles';
import Container from '@material-ui/core/Container';
import clsx from 'clsx';

export default function ContactUsGetinTouch({ data, setRegionalAddress, basePath }) {
  const classes = myStyles(data?.contentfulContactUs);
  const [prevDynamicForm, setPrevDynamicForm] = useState();
  const { partnerMetaData } = useContext(ThemeContext);
  const [dynamicForm, setDynamicForm] = useState();
  const [isSubmitSuccess, setIsSubmitSuccess] = useState(false);
  const [isShowError, setIsShowError] = useState(false);
  const [errMsg,setErrMsg]=useState('');
  const [isSubmitEnabled, setIsSubmitEnabled] = useState(true)
  function handleDynamicFormSubmit(formData) {
    setIsSubmitEnabled(false)
    const body = {
      trace_id: uuidv4(),
      partner: partnerMetaData.partner,
      appType: partnerMetaData.appType,
      processor: partnerMetaData.processor,
      data: {
        ...formData,
        // message: 'test gshongkong site in dev 5.05pm',
        subject: dynamicForm?.emailSubjectLine,
        formName: dynamicForm?.formName,
        deliveryEmail: dynamicForm.deliveryEmail,
        metadata: {
          CliNbr: partnerMetaData.clientNumber,
          LeadSource: partnerMetaData.leadSource,
        },
      },
    };
    fetch(process.env.GATSBY_EMAIL_NOTIFICATION_URL, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(body),
    })
      .then((res) => {
        if (res.status === 200) {
          setDynamicForm();
          setIsSubmitSuccess(true);
        }else{
          return res.json()
        }
      })
      .then(result=>{
        if(result?.message){
          setIsShowError(true)
          setErrMsg(result?.message)
        }
      })
      .catch((err) =>{
        setIsShowError(true)
        setErrMsg(data?.contentfulContactUs?.formFailureHeader)
        setIsSubmitEnabled(true)
      });
  }
  return (
    <>
      <Grid className="contact-us-cls" locId="contactUs">
      <div className={clsx("dot-matrix",classes["dot-matrix"])}>
      <div className={clsx("capsules-bg",classes["capsules-bg"])}>
        <Container maxWidth="xl">
          <div className='content_wrap'>
        <>
          {!dynamicForm && !isSubmitSuccess && (
                <h1 className="main-heading" locId="contactUsHeader">
                  {data?.contentfulContactUs?.contactUsHeader}
                </h1>
          )}
          {(!dynamicForm && isSubmitSuccess) && (
            <h2 className="get-touch-thanks-form" locId="formSuccessHeader">
                  {data?.contentfulContactUs?.formSuccessHeader}
                </h2>
          )}

          {dynamicForm && (
                <h2 className="get-touch-sample-form" locId="formHeading">
                  <div
                    tabIndex={'0'}
                    className={clsx('left-arrow', classes['bg-left-arrow'])}
                    onClick={
                      prevDynamicForm
                        ? () => {
                            setDynamicForm(prevDynamicForm);
                            setPrevDynamicForm();
                          }
                        : () => {
                            setDynamicForm();
                          }
                    }
                    onKeyDownCapture={(e) => {
                      if (e.key == 'Enter') {
                        if (prevDynamicForm) {
                          setDynamicForm(prevDynamicForm);
                          setPrevDynamicForm();
                        } else {
                          setDynamicForm();
                        }
                      }
                      return false;
                    }}
                    locId="leftArrowImage"
                  ></div>
                  {dynamicForm?.formHeading}
                </h2>
          )}
          {!dynamicForm && (
            <Grid container>
              {!isSubmitSuccess && (
                <div className="get-touch-wrap">
                <Grid container>
                <Grid item xl={8} lg={8} md={12} sm={12} xs={12}>
                  <p className="paratitle" locId="contactUsSubHeader">
                    {data?.contentfulContactUs?.contactUsSubHeader}
                  </p>
                  <div className="six-button">
                    {data?.contentfulContactUs?.chipButton?.chipButtons?.map((cb, index) => {
                      
                      return(
                        cb?.isChipButtonLink?
                      <Button
                        key={index}
                        variant="outlined"
                        size="small"
                        className="button-align"
                        href={cb?.chipButtonLink?.link?.slug?`/${basePath}/${cb?.chipButtonLink?.link?.slug}`:cb?.chipButtonLink?.link?.url}
                        locId={`chipButton${index}`}
                        tabindex="0"
                      >
                        {cb?.buttonText}
                      </Button>:
                      <Button
                      key={index}
                      variant="outlined"
                      size="small"
                      className="button-align"
                      onClick={() => {
                        setRegionalAddress(cb);
                        setDynamicForm(cb);
                      }}
                      locId={`chipButton${index}`}
                      tabindex="0"
                    >
                      {cb?.buttonText}
                    </Button>
                    )})}
                  </div>
                </Grid>
                </Grid>
                </div>
              )}
              {isSubmitSuccess && (
                <>
                  <Grid container className="thanks-response-section">
                  <Grid item xl={6} lg={6} md={7} sm={12} xs={12}>
                    <p className="paratitle-thanks-form" locId="formSuccessSubHeader">
                      {data?.contentfulContactUs?.formSuccessSubHeader}
                    </p>
                    <a className="meet-team" locId="formSuccessBodyText" href={data?.contentfulContactUs?.formSuccessBodyLink}>
                    
                        {data?.contentfulContactUs?.formSuccessBodyText}
                    </a>
                    <div>
                      <Button
                        tabindex="0"
                        variant="outlined"
                        className="btn-join-us"
                        locId="buttonText"
                        href={
                          data?.contentfulContactUs?.buttonTextLink
                            ? data?.contentfulContactUs?.buttonTextLink
                            : '#'
                        }
                      >
                        {data?.contentfulContactUs?.buttonText}
                      </Button>
                    </div>
                  </Grid>
                  </Grid>
                </>
              )}
            </Grid>
          )}
        </>

        {dynamicForm && (
          <>
          <div className='form_section'>
            <Grid container>
              <Grid item xl={7} lg={7} md={11} sm={12} xs={12} className="left-allign-title">
                <p className="paratitle-sample-form" locId="formSubHeading">
                  {dynamicForm?.formSubHeading}
                </p>
                {dynamicForm?.formInfoText && (
                  <p className="sub-paratitle-sample-form" locId="formInfoText">
                    {dynamicForm?.formInfoText}
                  </p>
                )}
                <div className="text-fields" locId="dynamicForm">
                  <FormContainer
                    {...dynamicForm}
                    data={data}
                    handleDynamicFormSubmit={handleDynamicFormSubmit}
                    setIsSubmitSuccess={setIsSubmitSuccess}
                    setRegionalAddress={setRegionalAddress}
                    setDynamicForm={setDynamicForm}
                    dynamicForm={dynamicForm}
                    isSubmitEnabled={isSubmitEnabled}
                    setPrevDynamicForm={setPrevDynamicForm}
                  />
                </div>
              </Grid>
            </Grid>
            </div>
          </>
        )}
        </div>
        </Container>
        </div>
        </div>
      </Grid>
      <Backdrop
        className="cust-class"
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isShowError}
      >
        <Dialog
          open={isShowError}
          keepMounted
          aria-describedby="alert-dialog-slide-description"
          fullWidth
          maxWidth={'xl'}
          className="error-box"
          style={{ position: 'absolute', top: 0, padding: 0, margin: 0, width: '100%' }}
        >
          <div className="cust-class">
            <DialogTitle className="error-msg">
              <span>
                {errMsg}
              </span>
              <button onClick={() => setIsShowError(false)} className="close-btn">
                Close
              </button>
            </DialogTitle>
          </div>
        </Dialog>
      </Backdrop>
    </>
  );
}
