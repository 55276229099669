import React from 'react';

const IframeFormContainer = ({ src }) => (
  <>
    <iframe
      src={src}
      width="100%"
      height="500"
      type="text/html"
      frameBorder="0"
      allowtransparency="true"
    />
  </>
);

export default IframeFormContainer;
