import React, { useCallback, useRef } from 'react';
import JsonSchemaForm from '@rjsf/material-ui/v5';
import {
  get, filter, uniqWith, isEqual
} from 'lodash';
import { ThemeProvider } from '@mui/material/styles';
import Container from '@mui/material/Container';
import DefaultFormTemplate from './templates/defaultFormTemplate';
import defaultUISchema from './templates/defaultFormTemplate/uiSchema';
import FormFooter from './templates/FormFooter';
import Themes from './templates/styles';

const DynamicForm = ({
  template,
  errorSchema,
  onSubmit,
  schema = {},
  uiSchema = {},
  themeName = 'DefaultFormTemplate',
  formSubmit,
  isSubmitEnabled
}) => {
  const myRef = useRef(null);

  const handleSubmit = ({ formData: submittedFormData }) => {    
    onSubmit?.(submittedFormData);
  };

  const transformErrors = useCallback((formErrors) => formErrors.map((error) => {
    error.message = errorSchema[error.property.replace('.', '')]?.message[error.name] || error.message;
    return error;
  }));

  const handleBlur = useCallback((...args) => {
    const $this = myRef.current;
    const fieldPath = args[0].split('_').slice(1);
    const { formData: blurFormData, errors, errorSchema: stateErrorSchema } = $this.state;
    const { errors: _errors, errorSchema: validatedErrorSchema } = $this.validate(blurFormData);
    const prevOtherFieldErrors = filter(errors, (error) => error.property !== `.${fieldPath}`);
    const fieldErrors = filter(_errors, ['property', `.${fieldPath}`]);
    const newFieldErrorSchema = get(validatedErrorSchema, fieldPath);
    $this.setState({
      errors: uniqWith([...prevOtherFieldErrors, ...fieldErrors], isEqual),
      errorSchema: { ...stateErrorSchema, [fieldPath]: newFieldErrorSchema }
    });
  });

  return (
    <ThemeProvider theme={Themes[themeName]}>
      <Container maxWidth={false} disableGutters>
        <JsonSchemaForm
          ref={myRef}
          onBlur={handleBlur}
          onSubmit={handleSubmit}
          schema={schema}
          showErrorList={false}
          noHtml5Validate
          transformErrors={transformErrors}
          uiSchema={{
            'ui:ObjectFieldTemplate': template || DefaultFormTemplate,
            ...defaultUISchema,
            ...uiSchema
          }}
        >
          <FormFooter isSubmitEnabled={isSubmitEnabled} formSubmit={formSubmit} />
        </JsonSchemaForm>
      </Container>
    </ThemeProvider>
  );
};
export default DynamicForm;
