import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import JsonFormContainer from './JsonFormContainer';
import IframeFormContainer from './IframeFormContainer';
import { Button } from '@mui/material';
const FormContainer = ({
  handleBackEvent,
  handleDynamicFormSubmit,
  formType,
  data,
  iFrameLink = '',
  jsonFormQuery = {},
  heading,
  description,
  hasNestedButtons,
  nestedButtons,
  setDynamicForm,
  dynamicForm,
  setRegionalAddress,
  setPrevDynamicForm,
  isSubmitEnabled,
  ...rest
}) => {
  return (
    <Grid container>
    
      <Grid item md={12} xs={12}>
        {formType && !hasNestedButtons && (
          <JsonFormContainer isSubmitEnabled={isSubmitEnabled} formSubmit={data?.contentfulContactUs?.formSubmit} {...jsonFormQuery} handleDynamicFormSubmit={handleDynamicFormSubmit} />
        )}
        
        {!formType && !hasNestedButtons && <div className="form_wrap"><IframeFormContainer src={iFrameLink} /></div>}
        
        {!formType && hasNestedButtons && (
          <Grid className="getintouch-btn-top">
            {nestedButtons?.chipButtons?.map((cb, idx) => (
              <Button
                variant="outlined"
                size="small"
                className="button-align"
                onClick={() => {
                  setPrevDynamicForm(dynamicForm)
                  setRegionalAddress(cb);
                  setDynamicForm(cb);
                }}
                tabindex="0"
              >
                {cb?.buttonText}
              </Button>
            ))}
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default FormContainer;
