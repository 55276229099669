import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import {
  Typography,
  List,
  ListItem,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import './GlobalPresence.scss';
import { myStyles } from './styles';
import clsx from 'clsx';
import Container from '@material-ui/core/Container';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS } from '@contentful/rich-text-types';
export default function ContactUsGlobalPresence({ regionalAddress, data }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));
  const classes = myStyles(data.contentfulContactUs);
  const [enable, setEnable] = useState(true);
  const [hoverState, setHoverState] = useState(false);
  const middleText = data?.contentfulContactUs.middleText;
  const subMiddleText = data?.contentfulContactUs.subMiddleText;
  const worldMapImg = data?.contentfulContactUs.bigWorldMapImage?.file?.url + '?fm=webp&q=100';
  const mapImgTitle = data?.contentfulContactUs.bigWorldMapImage?.description;
  const showOfficeLabel = data?.contentfulContactUs.globalAddresses[0].contact_us[0].expansionText;
  const hideOfficeLabel =
    data?.contentfulContactUs.globalAddresses[0].contact_us[0].expansionSubText;
  const smallWorldMapImg = data?.contentfulContactUs.smallWorldMapImage?.file?.url;
  const smallMapImgTitle = data?.contentfulContactUs.smallWorldMapImage?.description;
  const officeDetailData = data?.contentfulContactUs.globalAddresses;
  const handleClick = () => {
    enable ? setEnable(false) : setEnable(true);
  };
  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      enable ? setEnable(false) : setEnable(true);
    }
  };
  const richtextOptions = {
    renderText: (text) => {
      return text.split('\\n').reduce((children, textSegment, index) => {
        return [...children, index > 0 && <br key={index} />, textSegment];
      }, []);
    },
  };
  return (
    <>
      <Grid className="contactus-cls">
        <div className={clsx('bg-donuts-left', classes['bg-donuts-left'])}></div>
        <Container maxWidth="xl">
          <Grid className="blue-section">
            <Grid container item className="row-container">
              <Grid item xl={2} lg={2} md={3} sm={12} xs={12}>
                <div className="items">
                  <h2 locId="headerText">{regionalAddress?.regionalAddressHeading?.headerText}</h2>
                  <Typography className="contact-details" locId="regionalAddressHeading">
                    {regionalAddress?.regionalAddressHeading?.description?.raw &&
                      documentToReactComponents(
                        JSON.parse(regionalAddress?.regionalAddressHeading?.description?.raw)
                      )}
                  </Typography>
                </div>
              </Grid>
              <Grid container item xl={10} lg={10} md={9} sm={12} xs={12} className="divider">
                {regionalAddress?.regionalAddresses?.map((ra, index) => (
                  <Grid
                    item
                    xl={4}
                    lg={4}
                    md={12}
                    sm={12}
                    xs={12}
                    className="items"
                    key={ra?.headerText}
                  >
                    <h2 locId={`regionalAddressesHeaderText${index}`}>{ra?.headerText}</h2>
                    <Typography
                      className="contact-details"
                      locId={`regionalAddressesDescriptionText${index}`}
                    >
                      {ra?.description?.raw &&
                        documentToReactComponents(
                          JSON.parse(ra?.description?.raw),
                          richtextOptions
                        )}
                    </Typography>
                  </Grid>
                ))}
              </Grid>
            </Grid>
            {/* Contact section ends here*/}
            {/* Header text and map section */}
            <Grid container item xl={12} lg={12} md={12} sm={12} xs={12} className="map-section">
              <Grid item xl={1}>
                &nbsp;
              </Grid>
              <Grid item xl={11} lg={12} md={12} sm={12} xs={12} className="map-details">
                <Grid item xl={8} lg={8} md={9} sm={12} xs={12}>
                  <h2>{middleText}</h2>
                  <Typography className="sub-title">{subMiddleText}</Typography>
                </Grid>
                <Grid
                  item
                  xl={12}
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  className={clsx('map-image', classes['map-image-matrix'])}
                >
                  {isMobile ? (
                    <img src={smallWorldMapImg} alt={smallMapImgTitle} />
                  ) : (
                    <img src={worldMapImg} alt={mapImgTitle} />
                  )}
                  {/* For mobile */}
                  <Accordion className="locationdp">
                    {enable ? (
                      <AccordionSummary
                        className="accordian-header"
                        onClick={handleClick}
                        onKeyDown={handleKeyDown}
                        expandIcon={
                          hoverState ? (
                            <img
                              src={data?.contentfulContactUs?.plusHoverImage?.file?.url}
                              alt={data?.contentfulContactUs?.plusHoverImage?.description}
                            />
                          ) : (
                            <img
                              src={data?.contentfulContactUs?.plusImage?.file?.url}
                              alt={data?.contentfulContactUs?.plusImage?.description}
                            />
                          )
                        }
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        onMouseOver={() => setHoverState(true)}
                        onMouseOut={() => setHoverState(false)}
                      >
                        <Typography className="butn-txt">{showOfficeLabel}</Typography>
                      </AccordionSummary>
                    ) : (
                      <AccordionSummary
                        className="accordian-header"
                        onClick={handleClick}
                        onKeyDown={handleKeyDown}
                        expandIcon={
                          hoverState ? (
                            <img
                              src={data?.contentfulContactUs?.minusHoverImage?.file?.url}
                              alt={data?.contentfulContactUs?.minusHoverImage?.description}
                            />
                          ) : (
                            <img
                              src={data?.contentfulContactUs?.minusImage?.file?.url}
                              alt={data?.contentfulContactUs?.minusImage?.description}
                            />
                          )
                        }
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        onMouseOver={() => setHoverState(true)}
                        onMouseOut={() => setHoverState(false)}
                      >
                        <Typography className="butn-txt">{hideOfficeLabel}</Typography>
                      </AccordionSummary>
                    )}
                    <AccordionDetails className="accordian-summary">
                      {officeDetailData.length > 0 ? (
                        <List>
                          {officeDetailData.map((item, i) => (
                            <ListItem key={item.entryTitle}>
                              {item.description.raw ? (
                                <Grid item sm={12} xs={12}>
                                  <h4>{item.headerText}</h4>
                                  <Typography>
                                    {documentToReactComponents(JSON.parse(item.description.raw))}
                                  </Typography>
                                </Grid>
                              ) : (
                                <></>
                              )}
                            </ListItem>
                          ))}
                        </List>
                      ) : (
                        <></>
                      )}
                    </AccordionDetails>
                  </Accordion>
                  {/* For mobile ends here*/}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Grid>
    </>
  );
}