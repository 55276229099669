import createBreakpoints from '@material-ui/core/styles/createBreakpoints';
import { createTheme } from '@mui/material/styles';

const breakpoints = createBreakpoints({});

const DefaultFormTemplateTheme = createTheme({
  components: {
    MuiInputBase: {
      defaultProps: {
        variant: 'outlined',
      },
    },
  },
});

const TwoColumnTemplateTheme = createTheme({
  components: {
    MuiContainer: {
      styleOverrides: {
        root: {
          marginLeft: '0px',
          marginRight: '0px',
          [breakpoints.up('sm')]: {
            width: '100%',
          },
        },
      },
    },
    MuiGrid: {
      styleOverrides: {
        root: { [breakpoints.down('xs')]: { width: '100%' } },
      },
    },
    MuiInputBase: {
      defaultProps: {
        variant: 'outlined',
      },
    },
    MuiButtonBase: {
      styleOverrides: {
        root : {
          '&:focus-visible' :{
            outline: 'black solid 2px',
          }
        }
      }
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          [breakpoints.up('sm')]: {
            width: '100%',
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        multiline: {
          height: 'auto',
        },
      },
    },
  },
});

export default {
  DefaultFormTemplateTheme,
  TwoColumnTemplateTheme,
};
